const getSectionColor = (section) => {
  const sectionColors = {
    one: {
      primary: "#9EA93F",
      secondary: "#6F8A5C",
    },
    two: {
      primary: "#F3E6A0",
      secondary: "#E6C034",
    },
    three: {
      primary: "#A0B9DE",
      secondary: "#6167B5",
    },
    four: {
      primary: "#C26B5D",
      secondary: "#764841",
    },
    five: {
      primary: "#E7CFCD",
      secondary: "#C98F94",
    },
  };
  if (section in sectionColors) {
    return sectionColors[section];
  } else {
    return sectionColors["one"];
  }
};

export { getSectionColor };
